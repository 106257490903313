import React from "react";
import Guides from "./components/Guides";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CheckinEngine from "./components/CheckinEngine";
import Apartments from "./components/Apartments";
import Home from "./components/Home";
import { inject } from "@vercel/analytics";

inject();

const MainApp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const apartmentName = searchParams.get("apartment");
  const apartments = JSON.parse(process.env.REACT_APP_MY_APARTMENTS);

  let apartmentTitle = null;

  try {
    if (apartmentName && apartments.hasOwnProperty(apartmentName)) {
      apartmentTitle = apartments[apartmentName];
    } else {
      console.log("Apartment not found");
    }
  } catch (error) {
    console.error(error);
  }

  if (apartmentTitle !== null) {
    return (
      <CheckinEngine
        apartmentTitle={apartmentTitle}
        apartmentName={apartmentName}
      />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
};


const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

export default App;
