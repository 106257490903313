import Menu from './Menu';
import HomeCarousel from './HomeCarousel';
import React, { useState, useEffect, useRef } from 'react';
import './static/css/Home.css';

const Home = () => {
    
    return (
        <div className="homeComponent">
            <div className="titleBackground">
                <h1>Discover Napoli</h1>
            </div>
            <div className="naplesBrief">
                <p>
                    Discover Naples: The Heart of Italy's Southern Charm. Nestled on Italy's sun-kissed southwestern coast, 
                    Naples stands as a vibrant testament to ancient allure and modern dynamism. With the looming silhouette 
                    of Mount Vesuvius in the background and the gentle lull of the Tyrrhenian Sea at its doorstep, this city 
                    is a mesmerizing blend of rich history, delectable cuisine, and infectious energy.
                </p>
                <p>
                    Meander through narrow cobblestone streets echoing tales of yesteryears, discover Baroque cathedrals 
                    and archaeological treasures, and savor the authentic flavors of the world-renowned Neapolitan pizza. 
                    From the poetic ruins of Pompeii to the lively bustle of Piazza del Plebiscito, Naples invites travelers 
                    to experience a slice of Italy that's as timeless as it is transformative.
                </p>
                <p>
                    Come, let the magic of Naples captivate your senses, and let every moment become a cherished memory.
                </p>
            </div>
    
        </div>
    );
}

export default Home;
